import React from 'react'
import Layout from 'gatsby-theme-kn/src/components/modules/Layout'
import BodyBlocks from 'gatsby-theme-kn/src/components/technical/BodyBlocks'

const DemoPageHomeoffice = () => {
  const body = [
    {
      component: 'hero_block',
    },
    {
      component: 'dealer_search_block',
    },
    {
      component: 'triple_teaser_block',
      headline: 'Triple Teaser Test',
      teaser: [
        {
          component: 'stacked_teaser_item',
          image:
            'https://res.cloudinary.com/koenig-neurath/images/dqvqudgqpuwgcwpjmwkd/location-bilder-podeste-talo-s-lamiga-net-work-place-lounge-chair-de-en-fr-nl-7145.jpg',
          headline: 'Eine Überschrift',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
          link: '/',
          link_text: 'Ein Link',
        },
        {
          component: 'postit_teaser_item',
          text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens.',
          link: '/',
        },
        {
          component: 'polaroid_teaser_item',
          link: '/',
          image:
            'https://res.cloudinary.com/koenig-neurath/images/dqvqudgqpuwgcwpjmwkd/location-bilder-podeste-talo-s-lamiga-net-work-place-lounge-chair-de-en-fr-nl-7145.jpg',
          caption: 'Eine Bildbeschreibung',
        },
      ],
    },
    {
      component: 'header_block',
      kicker: 'Kicker',
      headline:
        'Eine Beispiel-Überschrift die vllt. sogar mal etwas länger sein könnte',
      subheadline: 'Subheadline',
      style: 'spacewhy',
    },
    {
      component: 'header_block',
      kicker: 'Kicker',
      headline:
        'Eine Beispiel-Überschrift die vllt. sogar mal etwas länger sein könnte',
      subheadline: 'Subheadline',
      style: 'spacehowto',
    },
    {
      component: 'header_block',
      kicker: 'Kicker',
      headline:
        'Eine Beispiel-Überschrift die vllt. sogar mal etwas länger sein könnte',
      subheadline: 'Subheadline',
      style: 'spaceknowhow',
    },
    {
      component: 'header_block',
      kicker: 'Kicker',
      headline:
        'Eine Beispiel-Überschrift die vllt. sogar mal etwas länger sein könnte',
      subheadline: 'Subheadline',
      style: 'spacetippstools',
    },
    {
      component: 'header_block',
      kicker: 'Kicker',
      headline:
        'Eine Beispiel-Überschrift die vllt. sogar mal etwas länger sein könnte',
      subheadline: 'Subheadline',
      style: 'exclamationmark',
    },
    {
      component: 'header_block',
      kicker: 'Kicker',
      headline: 'Eine Beispiel-Überschrift ',
      subheadline: 'Subheadline',
      style: 'marker',
    },
    {
      component: 'header_block',
      kicker: 'Kicker',
      headline:
        'Eine Beispiel-Überschrift die vllt. sogar mal etwas länger sein könnte',
      subheadline: 'Subheadline',
      style: '',
    },
    {
      component: 'interview_block',
      interview_sections: [
        {
          question: 'Frage 1 vom Interviewer',
          answer: 'Antwort 1 von der Person, die interviewt wird.',
        },
        {
          question: 'Frage 2 vom Interviewer',
          answer: 'Antwort 2 von der Person, die interviewt wird.',
        },
        {
          question: 'Frage 3 vom Interviewer',
          answer: 'Antwort 3 von der Person, die interviewt wird.',
        },
      ],
    },
    {
      component: 'checklist_block',
      kicker: 'Kicker',
      headline: 'Testüberschrift',
      subheadline: 'Subheadline',
      list: [
        {
          title: 'Eine wunderbare Heiterkeit',
          subtitle:
            'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
        },
        {
          title: 'Eine wunderbare Heiterkeit',
          subtitle:
            'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
        },
        {
          title: 'Eine wunderbare Heiterkeit',
          subtitle:
            'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.',
        },
      ],
      download_link: '/',
      download_text: 'Hier die Checklist downloaden',
    },
    {
      flow: true,
      component: 'text_block',
      kicker: 'Kicker',
      headline: 'Testüberschrift',
      subheadline: 'Subheadline',
      text: 'Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken. Wenn das liebe Tal um mich dampft, und die hohe Sonne an der Oberfläche der undurchdringlichen Finsternis meines Waldes ruht, und nur einzelne Strahlen sich in das innere Heiligtum stehlen, ich dann im hohen Grase am fallenden Bache liege, und näher an der Erde tausend mannigfaltige Gräschen mir merkwürdig werden; wenn ich das Wimmeln der kleinen Welt zwischen Halmen, die unzähligen, unergründlichen Gestalten der Würmchen, der Mückchen näher an meinem Herzen fühle, und fühle die Gegenwart des Allmächtigen, der uns nach seinem Bilde schuf, das Wehen des Alliebenden, der uns in ewiger Wonne schwebend trägt und erhält; mein Freund! ',
    },
    {
      component: 'text_with_image_block',
      paper_style: true,
      image:
        'https://res.cloudinary.com/koenig-neurath/images/dqvqudgqpuwgcwpjmwkd/location-bilder-podeste-talo-s-lamiga-net-work-place-lounge-chair-de-en-fr-nl-7145.jpg',
      caption: 'Ich bin so glücklich, mein Bester!',
      kicker: 'Kicker',
      headline: 'Testüberschrift',
      subheadline: 'Subheadline',
      text: 'Eine _wunderbare_ **Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.',
    },
    {
      component: 'text_with_image_block',
      paper_style: true,
      style: 'polaroid',
      image:
        'https://res.cloudinary.com/koenig-neurath/images/dqvqudgqpuwgcwpjmwkd/location-bilder-podeste-talo-s-lamiga-net-work-place-lounge-chair-de-en-fr-nl-7145.jpg',
      caption: 'Ich bin so glücklich, mein Bester!',
      kicker: 'Kicker',
      headline: 'Testüberschrift',
      subheadline: 'Subheadline',
      text: 'Eine _wunderbare_ **Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.',
    },
    {
      component: 'text_with_image_block',
      paper_style: true,
      style: 'postit',
      text_vertical_align: 'middle',
      image:
        'https://res.cloudinary.com/koenig-neurath/images/dqvqudgqpuwgcwpjmwkd/location-bilder-podeste-talo-s-lamiga-net-work-place-lounge-chair-de-en-fr-nl-7145.jpg',
      caption: 'Ich bin so glücklich, mein Bester!',
      kicker: 'Kicker',
      headline: 'Testüberschrift',
      subheadline: 'Subheadline',
      text: 'Eine _wunderbare_ **Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.',
      postit_text:
        'Eine _wunderbare_ **Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.',
    },
    {
      component: 'postit_block',
      text_size: 'lg',
      text: 'Eine _wunderbare_ **Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.',
    },
    {
      component: 'postit_block',
      text_size: 'xl',
      text: 'Eine _wunderbare_ **Heiterkeit** hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken.',
    },
    {
      component: 'polaroid_block',
      image:
        'https://res.cloudinary.com/koenig-neurath/images/dqvqudgqpuwgcwpjmwkd/location-bilder-podeste-talo-s-lamiga-net-work-place-lounge-chair-de-en-fr-nl-7145.jpg',
      caption:
        'Dies ist eine Beispiel-Caption fürs Bild samt der Maximallänge!',
    },
  ]
  return (
    <Layout pageName="demo">
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default DemoPageHomeoffice
